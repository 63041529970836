md-sidenav {
	height: 100%;
	width: 300px;
	background-color: #455c6c;

	md-content {
		background-color: #455c6c;
	}
}

.side-menu, .side-menu ul {
	list-style: none;
	padding: 0;
	margin-top: 0;
	background-color: #455c6c;
	color: white;
}

.side-menu .md-button.active {
	color: $positive;
}

.side-menu .menu-toggle-list a.md-button {
	display: block;
	padding: 0 16px 0 32px;
	text-transform: none;
	text-rendering: optimizeLegibility;
	font-weight: 500;
}

.side-menu .menu-toggle-list .md-button {
	display: block;
	padding: 0 16px 0 32px;
	text-transform: none;
}

.side-menu > li {
	border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.md-toolbar-tools {
	h3 {
	    font-weight: bold;
    	color: white;
	}
}

.md-button-toggle, .md-button-link {
	span {
		font-weight: bold !important;
	}
	
}
.md-button-toggle .md-toggle-icon.toggled {
	color: white;
	transform: rotateZ(-180deg);
	-webkit-transform: rotateZ(-180deg);
}

.md-button-toggle.toggled {
	background-color: rgba(0, 0, 0, 0.15);
}

.side-menu .md-button-toggle .md-toggle-icon {
	margin: auto 0 auto auto;
	padding-top: 12px;
	width: 15px;
	float: right;
	transition: transform .3s ease-in-out;
	-webkit-transition: -webkit-transform .3s ease-in-out;
}

.menu-toggle-list.ng-hide {
	max-height: 0;
}

.side-menu .md-button {
	border-radius: 0;
	color: inherit;
	cursor: pointer;
	font-weight: 400;
	line-height: 48px;
	margin: 0;
	max-height: 48px;
	overflow: hidden;
	padding: 0 16px;
	text-align: left;
	text-decoration: none;
	white-space: normal;
	width: 100%;
}

.menu-toggle-list {
	background: #fff;
	max-height: 1300px;
	overflow: hidden;
	position: relative;
	z-index: 1;

	-webkit-transition: 0.25s cubic-bezier(0.35, 0, 0.25, 1);
	-webkit-transition-property: max-height;
	-moz-transition: 0.25ss cubic-bezier(0.35, 0, 0.25, 1);
	-moz-transition-property: max-height;
	transition: 0.25ss cubic-bezier(0.35, 0, 0.25, 1);
	transition-property: max-height;
}