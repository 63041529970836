md-content {
	overflow-x: hidden;
}

.main-content {
	background: #f1f1f1;
}

.background-white {
	background: white;
}

md-dialog {
	border-radius: 0px;
	md-toolbar {
		background: $secondary !important;
		background-color: $secondary !important;
	}
}

.menu-container {
	background: $secondary;
}

div.logo {
	display: inline-block;
	background-color: white;
	border-radius: 100%;
	padding: 30px;
	box-sizing: content-box;
	background-position: center;
	background-repeat: no-repeat;
}

.main-container {
	height: inherit;
}

screen > md-toolbar {
	padding: 0px 20px;
}

screen > md-content {
	padding: 20px;
}

positive {
	color: $positive;
}

img.image-picker {
	width: 100px;
	height: 100px;
	object-fit: cover;
	object-position: center top;
	background-color: $positive;
}
