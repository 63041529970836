md-content.profile {
	md-toolbar {
		background-color: white;
		color: $secondary;

		h2 {
			padding-left: 20px;
		}
	}

	.md-whiteframe-4dp {
		padding: 0;
	}
}