.upload-file-dialog {

	width: 500px;

	.select-title {
		text-align: center;
		font-size: 16pt;

		span {
			padding-right: 0;
			padding-left: 0;
		}
		
		a {
			padding: 0;
			color: $positive;
			text-decoration: none;
		}
	}
	
	.drop-box {
		background: transparent;
		border: 5px dashed rgba(0,0,0,0.20);
		color: rgba(0,0,0,0.40);
		width: 100%;
		height: 200px;
		text-align: center;
		padding-top: 40px;
		margin-bottom: 15px;
	}

	.drop-box.dragover {
		border: 5px dashed rgb(0,188,212);
	}

	.drop-box.dragover-err {
		border: 5px dashed #F44336;
	}
}