.intro md-content {
	width: 100%;
	height: 100%;
	.intro-container {
		display: flex;
		justify-content: center;
		padding-bottom: 50px;
		margin-top: 200px;
		height: 350px;
		color: white;
		background-color: $positive;
		.title {
			font-size: 33pt;
    		padding-bottom: 40px;
		}
		#facebook-button {
			padding-left: 10px;
			padding-right: 10px;
			background: $secondary;
		}
		#facebook-button:hover {
			background: #374a56;
		}
		.message {
			text-align: center;
			padding-top: 5px;
			font-size: 10pt;
		}
	}
}

// .intro md-content {
// 	width: 100%;
// 	height: 100%;

// 	.intro-container {
// 		display: flex;
// 		justify-content: center;

// 		margin-top: 100px;


// 		.title {
// 			font-size: 33pt;
// 		}

// 		#facebook-button {
// 			padding-left: 10px;
// 			padding-right: 10px;
// 			background: $secondary;
// 		}

// 		#facebook-button:hover {
// 			background: #374a56;
// 		}

// 		.message {
// 			text-align: center;
// 			padding-top: 5px;
// 			font-size: 10pt;
// 		}
// 	}

// 	.inner-form {
// 		width: 600px;
// 		height: 440px;
// 		background-color: white;
// 	}

// 	md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
// 		color: white !important;
// 		background-color: white !important;
// 	}

// 	.subtitle {
// 		color: $positive;
// 	}

// 	md-card-header {
// 		background: $positive;
// 	}
// }
