.menu-toolbar {
	.page-title {
	    padding-left: 20px;
	}

	md-menu-bar {
	    min-height: inherit;

	    md-menu, .md-button {
			margin: 0;
			min-height: inherit;
	    }

	    .user-icon {
	    	margin-top: -25px;
	    }

	    .user-name {
	    	padding-left: 15px;
	    }
	}
}