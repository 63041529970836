.home {

	.chart-select {
	    margin-right: 20px;
	}

	.stats-total {
		font-weight: 600;
	}
	
	.select-image {
		width: 20px !important;
		height: 20px !important;
	}
	

	.communities {
		.community-tile {
			background: white;
			cursor: pointer;

			img {
				width: 100%;
				height: 100%;
			}

			md-grid-tile-footer {
			    background-color: rgba(69,92,108,0.75);
			}
		}

		.community-tile:focus {
			outline:0;
		}
	}
}