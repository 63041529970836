md-content.new-community {
	md-toolbar {
		background-color: white;
		color: $secondary;

		h2 {
			padding-left: 20px;
		}
	}

	.image-picker {
		margin-left: 50px;
		margin-right: 100px;
	}

	md-content {
		background: white;
	}
}