.community {

	.status {
		margin-bottom: 5px;
		.inactive-toolbar {
			.message {
				color: $warning;
			}
		}
		
		.waiting-toolbar {
			.message {
				color: $positive;
			}
		}
	}
	
	.community-badge {
		border-radius: 100%;
	    object-fit: cover;
		width: 60px;
		height: 60px;
	}

	.community-name {
	    margin-left: 20px;
	}

	md-tabs.md-default-theme md-ink-bar, md-tabs md-ink-bar {
		background-color: $positive;
		color: $positive;
	}

	.members-toolbar {
	    .md-button {
			height: inherit;
			margin: 0;
	    }
	}

	/*Remove blue outline when clicked on chrome*/
	tr:focus {
		outline:0;
	}

	.icon-icon_airbsit {
		margin-left: 12px;
		color: $positive;
		font-size: 18pt;
	}
}